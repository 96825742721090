import React from 'react'
import { Layout, Section, Container, SEO, EmailLink } from '../components'
import { makeContentStyle, textColumnStyle, tableStyle } from '../styles'

export const CounsellingPage = (props: any) => {
  const currPath = props.location.pathname
  const pageColor = 'grey'
  // This following line disables formatting linting
  /* eslint-disable prettier/prettier */
  return (
    <Layout currPath={currPath} pageColor={pageColor}>
      <SEO title="Beratung" />
      <Section index={0} color={pageColor} renderBg={true}>
        <Container>
          <div css={[textColumnStyle, makeContentStyle(pageColor)]}>
            <h1>Beratung vor Ort</h1>
            <p>
              Die Rote Hilfe hat in vielen Städten Ortsgruppen, die kostenlose Beratungen rund ums
              Thema Repression anbieten.
            </p>
            <table css={tableStyle}>
              <thead>
                <th>Ort</th>
                <th>Turnus</th>
                <th>Details</th>
              </thead>
              <tbody>
                <tr>
                  <td>Augsburg</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="augsburg@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Bamberg</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="bamberg@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Berlin-Kreuzberg</td>
                  <td>2. und 4. Donnerstag im Monat</td>
                  <td>Second-Hand-Buchladen Müßiggang, Oranienstr. 14a | 18 Uhr</td>
                </tr>
                <tr>
                  <td>Berlin-Neukölln</td>
                  <td>Mittwoch</td>
                  <td>Stadtteilladen Lunte, Weisestr 54 | 18:30-19:30 Uhr</td>
                </tr>
                <tr>
                  <td>Berlin-Wedding</td>
                  <td>1. und 3. Dienstag im Monat</td>
                  <td>Scherer8, Schererstr 8 | 19 Uhr</td>
                </tr>
                <tr>
                  <td>Bielefeld</td>
                  <td>2. Dienstag im Monat</td>
                  <td>Bürgerwache am Siegfriedplatz | 20 Uhr</td>
                </tr>
                <tr>
                  <td>Bochum</td>
                  <td>2. Donnerstag im Monat</td>
                  <td>Soziales Zentrum Bochum, Josephstr. 2 | 18:30-19:30 Uhr</td>
                </tr>
                <tr>
                  <td>Bonn</td>
                  <td>1. Mittwoch im Monat</td>
                  <td>Buchladen Le Sabot, Breite Str. 76 | 19:30-20:30 Uhr</td>
                </tr>
                <tr>
                  <td>Braunschweig</td>
                  <td>3. Freitag im Monat</td>
                  <td>Antifaschistisches Café, Eichtalstrasse 8 | 20 Uhr</td>
                </tr>
                <tr>
                  <td>Bremen</td>
                  <td>Montag in geraden Kalenderwochen</td>
                  <td> Jugendzentrum Friesenstraße, Friesenstr 124 | 20 Uhr</td>
                </tr>
                <tr>
                  <td>Chemnitz (OG Südwestsachsen)</td>
                  <td>1. Donnerstag im Monat</td>
                  <td>Kompott-Büro, Leipziger Str. 3-5 | ab 18 Uhr</td>
                </tr>
                <tr>
                  <td>Cottbus</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="cottbus@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Darmstadt</td>
                  <td>1. Freitag im Monat</td>
                  <td>Linkstreff, Landgraf-Philipps-Anlage 32 | 20 Uhr</td>
                </tr>
                <tr>
                  <td>Dortmund</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="dortmund@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Dresden</td>
                  <td>1., 3. und 5. Dienstag im Monat</td>
                  <td>AZ Conni, Rudolf-Leonhard-Straße 39 | 19 Uhr</td>
                </tr>
                <tr>
                  <td>Duisburg</td>
                  <td>Letzten Freitag im Monat</td>
                  <td>Syntopia, Gerokstr. 2 | 17-18 Uhr</td>
                </tr>
                <tr>
                  <td>Düsseldorf-Neuss</td>
                  <td>1. Dienstag im Monat</td>
                  <td>Linkes Zentrum Düsseldorf, Corneliusstr. 108 | 19:30 Uhr</td>
                </tr>
                <tr>
                  <td>Erfurt</td>
                  <td>1. Donnerstag im Monat</td>
                  <td>
                    Offene Arbeit Erfurt, Allerheiligenstrasse 9 &#47; Hinterhaus | 19-19:30 Uhr
                  </td>
                </tr>
                <tr>
                  <td>Frankfurt</td>
                  <td>2. und 4. Montag im Monat</td>
                  <td>Café Exzess, Leipziger Str. 91 | 20.00-21.30</td>
                </tr>
                <tr>
                  <td>Freiburg</td>
                  <td>1. Donnerstag im Monat</td>
                  <td>Linkes Zentrum, Glümerstr. 2 | 18.30-19.00 Uhr</td>
                </tr>
                <tr>
                  <td>Freiburg</td>
                  <td>3. Donnerstag im Monat</td>
                  <td>
                    Büro für Grenzenlose Solidarität, Grether Gelände, Adlerstr. 2 | 20.30-21.00
                  </td>
                </tr>
                <tr>
                  <td>Gießen/Marburg</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="giessen@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Göttingen</td>
                  <td>1. und 3. Mittwoch im Monat</td>
                  <td>Rote Hilfe Haus, Lange Geismar Str. 3 | 19:30 Uhr</td>
                </tr>
                <tr>
                  <td>Greifswald</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="greifswald@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Halle</td>
                  <td>2. und 4. Mittwoch im Monat</td>
                  <td>Infoladen, Ludwigstraße 37 | 18-19 Uhr</td>
                </tr>
                <tr>
                  <td>Hamburg</td>
                  <td>Dienstag</td>
                  <td>Centro Sociale/Kolleg, Sternstr 2 | 19:30-20 Uhr</td>
                </tr>
                <tr>
                  <td>Hannover</td>
                  <td>1. Sonntag im Monat</td>
                  <td>UJZ, Kornstraße 28&#47;32 | ab 16 Uhr</td>
                </tr>
                <tr>
                  <td>Hannover</td>
                  <td>2. Montag im Monat</td>
                  <td>UJZ, Kornstraße 28&#47;33 | ab 19 Uhr</td>
                </tr>
                <tr>
                  <td>Heidelberg</td>
                  <td>2.Mittwoch im Monat</td>
                  <td>Café Gegendruck, Fischergasse 2 | 19.30</td>
                </tr>
                <tr>
                  <td>Heilbronn</td>
                  <td>1. Dienstag im Monat</td>
                  <td>Soziales Zentrum Käthe, Wollhausstr. 49 | 19.00</td>
                </tr>
                <tr>
                  <td>Jena</td>
                  <td>2. und 4. Mittwoch im Monat</td>
                  <td>Infoladen, Schillergäßchen 5 | 19-21 Uhr</td>
                </tr>
                <tr>
                  <td>Karlsruhe</td>
                  <td>3. Donnerstag im Monat</td>
                  <td>Barrio 137, Luisenstr. 31 | 18.00-19.00 Uhr</td>
                </tr>
                <tr>
                  <td>Kassel</td>
                  <td>3. Freitag im Monat</td>
                  <td>Infoladen ...an der Halitstraße, Holländische Straße 88 | 18.00-20.00 Uhr</td>
                </tr>
                <tr>
                  <td>Kiel</td>
                  <td>Mittwoch alle zwei Wochen</td>
                  <td>Li(e)berAnders, Iltisstraße 34 | 20 Uhr</td>
                </tr>
                <tr>
                  <td>Köln-Leverkusen</td>
                  <td>1. und 3. Montag im Monat</td>
                  <td>LC 36 e.V., Ludolph Camphausen Straße 36 | 19-20 Uhr</td>
                </tr>
                <tr>
                  <td>Königs Wusterhausen</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="kw@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Landshut</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="landshut@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Leipzig</td>
                  <td>Freitag</td>
                  <td>LinXXnet, Brandstr. 15 | 17:30-18:30 Uhr</td>
                </tr>
                <tr>
                  <td>Lübeck</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="luebeck@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Magdeburg</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="magdeburg@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Mainz</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="mainz@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>München</td>
                  <td>Mittwoch</td>
                  <td>Schwanthaler Str. 139 | 18.00-19.00 Uhr</td>
                </tr>
                <tr>
                  <td>Nürnberg</td>
                  <td>2. und 4. Donnerstag im Monat</td>
                  <td>Schwarze Katze&#47;KOMM, Untere Seitenstr, 1 | 19.00-20.00 Uhr</td>
                </tr>
                <tr>
                  <td>Oberhausen&#47;westl. Ruhrgebiet</td>
                  <td>1. Mttwoch im Monat</td>
                  <td>Heinz-Renner-Haus, Severinstraße 1 | 18:30-20 Uhr</td>
                </tr>
                <tr>
                  <td>Oberhausen&#47;westl. Ruhrgebiet</td>
                  <td>3. Donnerstag im Monat</td>
                  <td>Fraktionsbüro Linke Liste Oberhausen, Friedensplatz 8 | 19-20 Uhr</td>
                </tr>
                <tr>
                  <td>Osnabrück</td>
                  <td>1. Freitag im Monat</td>
                  <td>Cafe Mano Negra, Alte Münze 12 | 18-19 Uhr</td>
                </tr>
                <tr>
                  <td>Plauen (OG Südwestsachsen)</td>
                  <td>Mittwoch</td>
                  <td>Infoladen Plauen, Thiergartner Straße 4 | 18 Uhr</td>
                </tr>
                <tr>
                  <td>Potsdam</td>
                  <td>1. Mittwoch</td>
                  <td>Kontext, Hermann-Elflein Straße 32 | 19 Uhr</td>
                </tr>
                <tr>
                  <td>Regensburg</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="regensburg@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Rostock</td>
                  <td>2. Donnerstag im Monat</td>
                  <td>Café Median, Niklotstraße 5&#47;6 | 18 Uhr</td>
                </tr>
                <tr>
                  <td>Salzwedel</td>
                  <td>1. Donnerstag im Monat</td>
                  <td>Ort auf Nachfrage | 19-19:30 Uhr</td>
                </tr>
                <tr>
                  <td>Strausberg</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="strausberg@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Stuttgart</td>
                  <td>1. und 3. Dienstag im Monat</td>
                  <td>Linkes Zentrum Lilo Herrmann, B&ouml;blinger Str. 105 | 19:00 Uhr</td>
                </tr>
                <tr>
                  <td>Südthüringen</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="sth@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Wiesbaden</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="wiesbaden@rote-hilfe.de" />
                  </td>
                </tr>
                <tr>
                  <td>Würzburg</td>
                  <td>auf Anfrage</td>
                  <td>
                    <EmailLink email="wuerzburg@rote-hilfe.de" />
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Hinweis: Die Beratung durch die Rote Hilfe stellt keine Rechtsberatung im Sinne des
              Rechtsberatungsgesetzes dar.
            </p>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default CounsellingPage
